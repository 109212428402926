import React from 'react';
import useSWR from 'swr/immutable';
import { Text } from 'dss-ui-library';
import { Column } from '../Column';
import { Row } from '../Row';
import {
  DropDownColumnProps,
  DropDownRowProps,
} from '../../../../../interfaces';
import { fetcher } from '@ncs-frontend-monorepo/utils';
import { apiUrl } from '../../../../../utils';
import { FetchedDropDown } from '../../../MobileProductMenu/dropdown';

interface Item {
  id: string | number;
}

export const Item: React.FC<Item> = React.memo(({ id }) => {
  const { data } = useSWR<FetchedDropDown>(
    `${apiUrl()}/content/pksite-dropdown-${id}`,
    fetcher,
  );

  return data ? (
    data.content?.component === 'ModuleMenuDropdownColumn' ? (
      <Column {...(data.content.props as DropDownColumnProps)} />
    ) : data?.content?.component === 'ModuleMenuDropdownRow' ? (
      <Row {...(data.content.props as DropDownRowProps)} />
    ) : (
      <Text appearance="t5">No menu items found</Text>
    )
  ) : null;
});
