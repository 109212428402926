import React from 'react';
import cx from 'classnames';
import styles from './DesktopProductMenu.module.scss';
import { Spacer, DroppingTabPanel } from 'dss-ui-library';
import { SitemapEntry } from '../../../interfaces';
import { Item } from './DropDown/Item';

interface DesktopProductMenuProps {
  isOpen: boolean;
  selectedTab: string;
  menuCategories: SitemapEntry[];
  onOutsideClick?: () => void;
}

export const DesktopProductMenu: React.FC<DesktopProductMenuProps> = React.memo(
  ({ isOpen, selectedTab, menuCategories, onOutsideClick }) => (
    <>
      <nav
        aria-label="product-categories"
        className={cx(styles.productNavigation, {
          [styles.open]: !!isOpen,
        })}
      >
        <div className={styles.container}>
          <Spacer x={2} b={2} t={3} block>
            {menuCategories.map((category) => (
              <DroppingTabPanel
                value={selectedTab}
                id={`${category.pageId}`}
                key={category.pageId}
              >
                <Item id={category.pageId} />
              </DroppingTabPanel>
            ))}
          </Spacer>
        </div>
      </nav>
      {isOpen && (
        <div className={styles.overlay} onClick={() => onOutsideClick?.()} />
      )}
    </>
  ),
);
